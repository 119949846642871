import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {gettingProjectPage } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { AppDispatch } from 'reduxTK/store/store';
import Skeleton from '@mui/material/Skeleton';
import DemandePret from 'components/demandePret/DemandePret';

const ProjectsPage: React.FC = () => {
    useEffect(() => {
        document.title = "Intergestion - Projets";
      }, []);
    interface RootState {
        staticPages: {
            project: {
                data: {
                    title: string;
                    content: string;
                }
                isLoading: boolean;
                error: Error | null;
            };
        };
    }

    const dispatch: AppDispatch = useDispatch();
    const projects = useSelector((state: RootState) => state.staticPages.project.data);
    const isLoading = useSelector((state: RootState) => state.staticPages.project?.isLoading);

    useEffect(() => {
        dispatch(gettingProjectPage());
    }, [dispatch]);

    return (
        <div>
            {isLoading ? (
                <>
                    <Skeleton variant="text" sx={{ fontSize: '3rem' }} width={200} />
                    <Skeleton variant="rectangular" height={200} />
                </>
            ) : (
              <>
              <p>here</p>
                  <div dangerouslySetInnerHTML={{ __html: projects?.content }} />
                  <DemandePret />
              </>
            )}
        </div>
    );
};

export default ProjectsPage;
