import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { gettingAboutUsPage } from 'reduxTK/slices/static-pages/staticPagesAsyncThunk';
import { AppDispatch } from 'reduxTK/store/store';
import Skeleton from '@mui/material/Skeleton';
import styles from './about.module.scss';
const AboutPage: React.FC = () => {
  useEffect(() => {
    document.title = "Intergestion - A propos";
  }, []);
  interface RootState {
    staticPages: {
      about: {
        data: {
          title: string;
          content: string;
        }
        isLoading: boolean;
        error: Error | null;
      };
    };
  }

  const dispatch: AppDispatch = useDispatch();
  const about = useSelector((state: RootState) => state.staticPages.about.data);
  const isLoading = useSelector((state: RootState) => state.staticPages.about.isLoading);

  useEffect(() => {
    dispatch(gettingAboutUsPage());
  }, [dispatch]);

  return (
    <div>
      {isLoading ? (
        <>
          <Skeleton variant="text" sx={{ fontSize: '3rem' }} width={200} />
          <Skeleton variant="rectangular" height={200}  />
        </>
      ) : (
        <div className={styles.videoContainer}>
      <video className={styles.styledVideo} controls>
        <source src="/intergestion-tuto.mp4" type="video/mp4" />
        Votre navigateur ne supporte pas la lecture vidéo.
      </video>
      </div>
       // <div dangerouslySetInnerHTML={{ __html: about?.content }} />
      )}
    </div>
  );
};

export default AboutPage;
