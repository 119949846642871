import { useAppDispatch } from 'common/hooks';
import styles from '../editProjectApplicant.module.scss';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getSituationProList } from 'reduxTK/slices/requestDropDowns/requestDropDownsAsyncThunk';

interface RootState {
  requestDropDowns: {
    situation_pro: {
      data: any;
    };
  };
}

interface CivilStatusBlockProps {
  handleInputChange: any;
  formData: any;
}

const CivilStatusBlock: React.FC<CivilStatusBlockProps> = ({ handleInputChange, formData }) => {
  const dispatch = useAppDispatch();

  const { data } = useSelector((state: RootState) => state.requestDropDowns.situation_pro);

  const civilStatus = [
    { label: 'Prenom', type: 'text', name: 'firstName', required: true },
    { label: 'Nom', type: 'text', name: 'lastName', required: true },
    { label: 'Adresse e-mail', type: 'email', name: 'email', required: true  },
    { label: 'Numéro de téléphone', type: 'tel', name: 'phone', required: true },
    {
      label: 'Situation professionnelle',
      type: 'select',
      name: 's_situation_pro',
      options: data,
      required: true,
    },
  ];

  useEffect(() => {
    dispatch(getSituationProList());
  }, [dispatch]);

  return (
    <div>
      <h2 className={styles.blockTitle}>ETAT CIVIL</h2>
      <div className={styles.inputsBlock}>
        {civilStatus.map((input, index) => (
          <div key={index} className={styles.inputContainer}>
            <label htmlFor={input.name}>
              {input.label}
              {input.required && <span>*</span>}
            </label>
            {input.type === 'select' ? (
              <select
                className={styles.input}
                name={input.name}
                value={formData ? formData[input.name] : ''}
                id={input.name}
                onChange={(e) => handleInputChange(input.name, e.target.value)}
                required={input.required}
              >
                {input.options?.map((option: any, index: number) => (
                  <option key={index} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            ) : (
              <input
                className={styles.input}
                type={input.type}
                name={input.name}
                id={input.name}
                defaultValue={formData ? formData[input.name] : ''}
                onChange={(e) => handleInputChange(input.name, e.target.value)}
                required={input.required}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CivilStatusBlock;
