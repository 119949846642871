import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'common/hooks';

import { getUserProfile } from 'reduxTK/slices/profile/profileAsyncThunk';
import { MdPerson } from 'react-icons/md';

import styles from './editProfilApplicantPage.module.scss';
import { editingProfile, updatePassword } from 'reduxTK/slices/authentication/authAsyncThunk';
import { emptyUserAfterEdit } from 'reduxTK/slices/authentication/authSlice';

const EditProfilApplicantPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const profile = useSelector((state: any) => state.profile.profile.data);

  const editSuccess = useSelector((state: any) => state.auth?.editSuccess);

  const [formState, setFormState] = useState({});
  const [passwordState, setPasswordState] = useState({ old_password: '', new_password: '' , confirmPassword: ''});
  const [confirmPassword, setConfirmPassword] = useState(false)

  const profileId = localStorage.getItem('profileId');

  const profileInputs = [
    { label: 'Titre', type: 'text', name: 'namePrefix', defaultValue: profile?.namePrefix },
    { label: 'Prénom', type: 'text', name: 'firstName', defaultValue: profile?.firstName },
    {
      label: 'Deuxième prénom',
      type: 'text',
      name: 'middleName',
      defaultValue: profile?.middleName,
    },
    { label: 'Nom', type: 'text', name: 'lastName', defaultValue: profile?.lastName },
    {
      label: 'Date de naissance',
      type: 'date',
      name: 'birthday',
      defaultValue: profile?.birthday,
    },
    { label: 'e-mail', type: 'email', name: 'email', defaultValue: profile?.email, disabled: true },
  ];

  const updatePasswordInpusts = [
    { label: 'Mot de passe actuel', type: 'password', name: 'old_password' },
    { label: 'Nouveau', type: 'password', name: 'new_password' },
    { label: 'Confirmer', type: 'password', name: 'confirmPassword' },
  ]

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setFormState({
      ...formState,
      [name]: e.target.value,
    });
  };

  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    setPasswordState({
      ...passwordState,
      [name]: e.target.value,
    });
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(editingProfile({ id: profileId, data: formState }));
    const data = {
      id: Number(profileId),
      old_password: passwordState.old_password,
      new_password: passwordState.new_password,
    };
    dispatch(updatePassword(data));
  };
  useEffect(() => {
    document.title = "Intergestion - Profil";
  }, []);
  useEffect(() => {
    if(passwordState.new_password === passwordState.confirmPassword){
      setConfirmPassword(true)
    }else{
      setConfirmPassword(false)
    }
  },[passwordState])

  useEffect(() => {
    dispatch(getUserProfile());
  }, [dispatch]);

  useEffect(() => {
    if(editSuccess){
      navigate('/ProfileDetail');
      dispatch(emptyUserAfterEdit())
    }
  },[editSuccess,dispatch,navigate])

  useEffect(() => {
    if (profile) {
      setFormState({
        namePrefix: profile?.namePrefix,
        firstName: profile?.firstName,
        middleName: profile?.middleName,
        lastName: profile?.lastName,
        birthday: profile?.birthday,
      });
    }
  }, [profile]);

  return (
    <div className={styles.editProfileContainer}>
      <div className={styles.headerContainer}>
        <div className={styles.profileLogo}>
          <MdPerson size={40} />
        </div>
        <h2>VOTRE PROFIL</h2>
      </div>
      <div className={styles.formContainer}>
        <form className={styles.form} onSubmit={handleSubmit}>
          {profileInputs.map((input, index) => (
            <div key={index} className={styles.inputContainer}>
              <label className={styles.label}>{input.label}</label>
              <input
                defaultValue={input.defaultValue}
                className={input.type === 'date' ? styles.dateInput : styles.input}
                type={input.type}
                name={input.name}
                disabled={input.disabled}
                onChange={(e) => handleInputChange(e, input.name)}
              />
            </div>
          ))}
          {updatePasswordInpusts.map((input, index) => (
            <div key={index} className={styles.inputContainer}>
              <label className={styles.label}>{input.label}</label>
              <input
                className={styles.input}
                type={input.type}
                name={input.name}
                onChange={(e) => handlePasswordChange(e, input.name)}
              />
            </div>
          ))}
          {
            !confirmPassword && passwordState.confirmPassword !== '' && (
              <div className={styles.confirmPassword}>
                <p>Les mots de passe ne correspondent pas</p>
              </div>
            )
          }
          <button type="submit" className={styles.editButton}>
            ENREGISTRER
          </button>
          <button
            type="button"
            className={styles.editButton}
            onClick={() => navigate('/applicant/profile')}
          >
            ANNULER
          </button>
        </form>
      </div>
    </div>
  );
};

export default EditProfilApplicantPage;
