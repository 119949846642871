import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'common/hooks';

import { getDureeCreditSouhaiteList } from 'reduxTK/slices/requestDropDowns/requestDropDownsAsyncThunk';

import styles from '../editProjectApplicant.module.scss';

const projectInputsWithFile = [
  { label: 'Description du projet:', type: 'textarea', name: 'note', required: true },
  {
    label: 'Présentation vidéo du projet',
    type: 'file',
    name: 's_url_project_media',
    required: false,
  },
];

interface RootState {
  requestDropDowns: {
    duree_credit_souhaite: {
      data: any;
    };
  };
}

interface ProjectBlockProps {
  handleInputChange: any;
  formData: any;
}

const ProjectBlock: React.FC<ProjectBlockProps> = ({ handleInputChange, formData }) => {
  const dispatch = useAppDispatch();

  const { data } = useSelector((state: RootState) => state.requestDropDowns.duree_credit_souhaite);

  const projectInputs = [
    {
      label: 'Montant revenus nets imposables annuel',
      type: 'text',
      name: 's_montant_impot',
      required: true,
    },
    {
      label: 'Montant total mensuel des crédits en cours',
      type: 'text',
      name: 's_montant_credit',
      required: true,
    },
    { label: 'Prix de vente à financer', type: 'text', name: 's_montant_global', required: true },
    {
      label: 'Dont prix acquisition terrain',
      type: 'text',
      name: 's_acquisition',
      required: false,
    },
    {
      label: 'Dont prix travaux et/ou construction',
      type: 'text',
      name: 's_montant_travaux',
      required: false,
    },
    {
      label: 'Montant de l’apport possible',
      type: 'text',
      name: 's_montant_apport',
      required: true,
    },
    {
      label: 'Mensualité maximum souhaitée',
      type: 'text',
      name: 's_montant_mens_max',
      required: true,
    },
    {
      label: 'Durée du crédit souhaitée',
      type: 'select',
      name: 's_duree_credit_souhait',
      options: data,
      required: true,
    },
  ];

  useEffect(() => {
    dispatch(getDureeCreditSouhaiteList());
  }, [dispatch]);

  return (
    <div>
      <h2 className={styles.blockTitle}>MON PROJET</h2>
      <div className={styles.inputsBlock}>
        {projectInputs.map((input, index) => {
          return (
            <div key={index} className={styles.inputContainer}>
              <label htmlFor={input.name}>
                {input.label}
                {input.required && <span>*</span>}
              </label>
              {input.type === 'select' ? (
                <select
                  className={styles.input}
                  name={input.name}
                  id={input.name}
                  value={formData ? formData[input.name] : ''}
                  onChange={(e) => handleInputChange(input.name, e.target.value)}
                  required={input.required}
                >
                  {input.options?.map((option: any, index: number) => {
                    return (
                      <option key={index} value={option.id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              ) : (
                <input
                  className={styles.input}
                  type={input.type}
                  name={input.name}
                  id={input.name}
                  defaultValue={formData ? formData[input.name] : ''}
                  onChange={(e) => handleInputChange(input.name, e.target.value)}
                  required={input.required}
                />
              )}
            </div>
          );
        })}
      </div>
      <div>
        {projectInputsWithFile.map((input, index) => {
          return (
            <div key={index}>
              {input.type === 'textarea' ? (
                <div className={styles.textAreaContainer}>
                  <label htmlFor={input.name}>
                    {input.label}
                    {input.required && <span>*</span>}
                  </label>
                  <textarea
                    className={styles.inputTextarea}
                    name={input.name}
                    id={input.name}
                    defaultValue={formData ? formData[input.name] : ''}
                    onChange={(e) => handleInputChange(input.name, e.target.value)}
                    required
                  />
                </div>
              ) : input.type === 'file' ? (
                <div className={styles.inputContainer}>
                  <label htmlFor={input.name}>
                    {input.label}
                    {input.required && <span>*</span>}
                  </label>
                  <input
                    className={styles.input}
                    type={input.type}
                    name={input.name}
                    id={input.name}
                    defaultValue={formData ? formData[input.name] : ''}
                    onChange={(e) => handleInputChange(input.name, e.target.value)}
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectBlock;
